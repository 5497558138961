<template>
  <div class="form">
    <section class="form-section">
      <FormRow :required="true">
        <template v-slot:label>お知らせ画像の選択</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-select">
              <select
                :class="{ 'form-control form-select-input': true, 'is-error': errors.templateImageId }"
                name="templateImageId"
                v-model="form.templateImageId"
              >
                <option value="" selected>選択してください</option>
                <option
                  v-for="item in topicTemplates"
                  :key="item.index"
                  :value="item.id"
                >{{item.name}}</option>
              </select>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>オリジナルをアップロードする</template>
        <template v-slot:content>
          <div class="form-content-row">
            <ul class="fileUpload">
              <li class="fileUpload-item">
                <Fileupload
                  @uploaded="setFileUpload"
                  @removed="removeFileUpload"
                  :initialUrl="initialImage"
                  :loading="isUploadLoading"
                />
              </li>
            </ul>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>タイトル</template>
        <template v-slot:labelNote>（20文字以内）</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              :class="{ 'form-control': true, 'is-error': errors.subject }"
              type="text"
              name="subject"
              v-trim
              v-maxlength
              maxlength="20"
              v-model="form.subject"
            />
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>本文</template>
        <template v-slot:labelNote>（1000文字以内）</template>
        <template v-slot:content>
          <div :class="{ 'form-content-row': true, 'is-error': errors.body }">
            <Editor ref="editor" v-model="form.body" :api-key="apikey" :init="init" />
          </div>
        </template>
      </FormRow>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import FormRow from '@/components/FormRow.vue';
import Fileupload from '@/components/Fileupload.vue';
import Editor from '@tinymce/tinymce-vue';
import error from '@/mixins/plugin/error';
import { stripTagsMaxLength } from '@/helpers/validators';

export default {
  data() {
    return {
      form: {},
      isUploadLoading: false,
      initialImage: '',
      apikey: process.env.VUE_APP_EDITOR_KEY,
      init: {
        language: 'ja',
        height: 500,
        menubar: false,
        table_toolbar: ['tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'],
        plugins: [
          'print preview importcss searchreplace autolink \
        autosave save directionality visualblocks visualchars fullscreen image link media template codesample \
        table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount \
        imagetools textpattern noneditable help charmap emoticons',
        ],
        toolbar: ['undo redo | formatselect | bold italic forecolor backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist | table | removeformat | help'],
      },
    }
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      topicTemplates: 'common/topicTemplates',
      newsDetail: 'news/newsDetail',
      newSubdomain: 'common/subdomain',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      isGmoOrOfficeLoginStoreAndStoreGroup: 'common/isGmoOrOfficeLoginStoreAndStoreGroup',
    }),
    newsId() {
      return this.$route.params.id;
    },
    subdomain() {
      if (this.$permission.isStoreOrGroup()) {
        return this.infor.event?.subdomain || null;
      } else {
        return (this.isGmoOrOfficeLoginStore ? this.newSubdomain : this.$route.params?.subdomain) || null;
      }
    },
    listRoute() {
      if (this.isGmoOrOfficeLoginStoreAndStoreGroup) {
        return 'ShopNews';
      } else {
        return 'News';
      }
    },
  },
  components: {
    FormRow,
    Fileupload,
    Editor
  },
  validations: {
    form: {
      templateImageId: { selectRequired: required },
      subject: { required, maxLength: maxLength(20) },
      body: { required, maxLength: stripTagsMaxLength(1000) },
    }
  },
  mixins: [error],
  methods: {
    initForm() {
      this.form = {
        subject: '',
        body: '',
        templateImageId: '',
        uploadImage: '',
      }
    },
    convertForm() {
      this.form= {
        ...this.form,
        ...this.newsDetail,
      }
      if (this.form.featuredImageType === 'upload') {
        this.initialImage = this.form.featuredImage?.url;
        this.form.uploadImage = this.form.featuredImage?.id;
      } else {
        this.form.templateImageId = this.form.featuredImage?.id;
      }
    },
    formatFormData() {
      if (this.form.uploadImage) {
        this.form.featuredImage = {
          type: 'upload',
          imageId: this.form.uploadImage,
        }
      } else if(this.form.templateImageId) {
        this.form.featuredImage = {
          type: 'selected',
          templateId: this.form.templateImageId,
        }
      } else {
        this.form.featuredImage = null;
      }
    },
    async validate() {
      this.formatFormData();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'topic');
        return false;
      } else {
        delete this.form.templateImageId;
        delete this.form.uploadImage;
        if (this.isEdit) {
          return { ...this.form, id: this.newsId };
        } else {
          return this.form;
        }
      }
    },
    async setFileUpload(url) {
      this.isUploadLoading = true;
      this.form.uploadImage = await this.$store.dispatch('common/uploadImage', {
        image: url,
      });
      this.isUploadLoading = false;
    },
    async removeFileUpload() {
      this.form.uploadImage = '';
    },
    async getDetail() {
      if (this.isEdit) {
        await this.$store.dispatch('news/getNewsDetail', {
          id: this.newsId,
          subdomain: this.subdomain,
        });
      }
    },
  },
  created() {
    this.initForm();
  },
  mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.getDetail(),
      this.$store.dispatch('common/getTopicTemplateList'),
    ]).finally(() => {
      if (this.isEdit) {
        this.convertForm();
      }
      this.$loading.clear(loading);
    });
  }
}
</script>

